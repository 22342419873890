import {getBasePath, mergeObjects} from './commonUtil';

export function qaEnvironment(countryCode) {
  let baseConfig = {
    exrEnv: 'qa',
    exrServiceApi: 'https://api-qat.fordcredit.com/',
    adobeSrc: 'https://assets.adobedtm.com/248d63c2ff6b/01cce4ecbac0/launch-EN3068e51f4ce14dacac576c5214f9e027-development.min.js',

    // OPTIMIZELY VALUES
    optimizelyKey: 'vKXydnpuakhqQQMPM7gYH',
    experimentVariation: 'exp_id_-_93',

    // TIMEOUT VALUES
    warningTimeMilliseconds: '120000',
    sessionEndTimeMilliseconds: '420000',

    // PREPOPULATE FORM FIELDS
    prepopulateApprovedCustomerTestDataUSA: false,
    prepopulateCreditFreezeCustomerTestDataUSA: false,
    prepopulateApprovedCustomerTestDataCAN: false,
    prepopulateCreditFreezeCustomerTestDataCAN: false,
    prepopulateQuebecCreditFreezeCustomerTestDataCAN: false,

    // WEB COMPONENT VALUES
    appModalURL: 'https://wwwuat.qualify.ford.com/webcompbutton/prequal-app-modal.js',
    minifiedCSSURL: 'https://wwwuat.qualify.ford.com/webcompbutton/prequal-app.css',

    // TARGET URLS
    specialEventTarget: 'https://www.buyfordnow.com/salesevent',

    // FLAGS
    offersAndIncentivesFlag: true,
    useEnterpriseReCaptcha: true,

    // DynaTrace URL
    dynatraceProdURL: 'https://uemm.dynatrace.ford.com:443/jstag/managed/8436fe71-6539-4ea3-aab8-a9985ae713d4/4372d5dc3ae6e275_complete.js',
    dynatraceQaURL: 'https://uemmqa.dynatrace.ford.com:443/jstag/managed/114d327e-ea9d-46cc-92d3-3967eaedacde/cd7453937351f738_complete.js',

    // AEM VALUES
    AEM_AUTHOR_URL: 'https://wwwqa.creditbrandauthorlb.ford.com',
    basePath: getBasePath('/finance/qualify', '/finance/prequalify', 'https://wwwqa.creditbrandauthorlb.ford.com')
  };
  const targetConfigUSA = {
    exploreVehiclesTarget: 'https://shop.ford.com/showroom/',
    prequalifyTarget: 'https://wwwqa.postprequalify.ford.com/finance/prequalify',
    shopTarget: 'https://shop.ford.com/showroom/',
    shopIncentivesTarget: 'https://shop.ford.com/showroom/?linktype=incentives#/',
    ocaTarget: 'https://wwwqa.ford.com/finance/apply/',
    countryCode: 'USA',
    AEM_PAGE_PATH: '/content/ucl/prequal/ford/en-us/tiles/landingpage',
    AEM_PUBLISHER_URL: 'https://wwwqa.ford.com/finance'
  };
  const targetConfigCanada = {
    exploreVehiclesTarget: 'https://shop.ford.ca/showroom/',
    prequalifyTarget: 'https://wwwqa.postprequalify.ford.ca/finance/prequalify',
    shopTarget: 'https://shop.ford.ca/showroom/',
    shopIncentivesTarget: 'https://shop.ford.ca/showroom/?linktype=incentives#/',
    ocaTarget: 'https://wwwqa.ford.ca/finance/apply/',
    countryCode: 'CAN',
    AEM_PAGE_PATH: '/content/ucl/prequal/ford/en-ca/tiles/landingpage',
    AEM_PUBLISHER_URL: 'https://wwwqa.ford.ca/finance'
  };

  const targetConfigFrenchCanada = {
    exploreVehiclesTarget: 'https://fr.shop.ford.ca/showroom/',
    prequalifyTarget: 'https://frqa.postprequalify.ford.ca/finance/prequalify',
    shopTarget: 'https://fr.shop.ford.ca/showroom/',
    ocaTarget: 'https://wwwqa.ford.ca/finance/apply/',
    shopIncentivesTarget: 'https://fr.shop.ford.ca/showroom/?linktype=incentives#/',
    countryCode: 'CAN',
    languageCode: 'FR',
    AEM_PAGE_PATH: '/content/ucl/prequal/ford/fr-ca/tiles/landingpage',
    AEM_PUBLISHER_URL: 'https://wwwqa.ford.ca/finance'
  };

  if (
    (window.location.host.includes('wwwqa.creditbrandauthorlb.ford.com') && window.location.href.includes('fr-ca')) ||
    window.location.host.includes('frqa.postprequalify.ford.ca') ||
    window.location.host.includes('frqa.ford.ca')
  ) {
    return {...baseConfig, ...targetConfigFrenchCanada};
  }
  baseConfig = mergeObjects(baseConfig, targetConfigUSA, targetConfigCanada, countryCode);
  return baseConfig;
}
